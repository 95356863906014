module.exports = {
  siteTitle: 'ErgZone - Row, Ski, Bike, Run', // <title>
  manifestIcon: 'src/assets/img/icon.svg',
  // social
  socialLinks: [
    {
      icon: 'far fa-envelope',
      name: 'Email',
      url: 'mailto:support@erg.zone',
    },
    {
      icon: 'fab fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/ergzone',
    },
    {
      icon: 'fab fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/groups/932043453844007/',
    },
    {
      icon: 'fab fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCjZW3Dtu4-YJ1KS0ihI6HeA',
    },
  ],
};
